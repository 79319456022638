import React, { Component } from "react"

import styles from "./styles.module.less"

import logoGif from "./img/logoGif.gif"
import colors from "./img/colors.png"
import kmnWeb from "./img/kmnWeb.png"
import billboard from "./img/billboard.png"
import fatherChild from "./img/fatherChild.png"

const ClosingBlock = () => {
  return (
    <div className={styles.ClosingBlock}>
      <div className={styles.gifContainer}>
        <img src={logoGif} className={styles.gif} />
      </div>
      <div className={styles.closingHero}>
        <div className={styles.details}>
          Like any good logo, it can be color, black and white, or grayscale.
          And whether it’s gracing a letterhead or a billboard, it’s always
          telling a story of reunification, reconciliation, and progress.
        </div>
        <div className={styles.heroImages}>
          <img src={colors} className={styles.colors} />
          <img src={kmnWeb} className={styles.kmnWeb} />
        </div>
      </div>
      <div className={styles.images}>
        <img src={billboard} className={styles.billboard} />
        <img src={fatherChild} className={styles.fatherChild} />
      </div>
    </div>
  )
}

export default ClosingBlock
