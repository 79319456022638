import React from "react"
import Fade from "react-reveal/Fade"
import styles from "./styles.module.less"

const QuoteAnimation = () => {
  return (
    <div className={styles.QuoteAnimation}>
      <Fade left>
        <p className={styles.firstLine}>&ldquo;See the inmate,</p>
      </Fade>
      {/* <br /> */}
      <Fade right>
        <p>
          not as a <span className={styles.span1}>criminal</span>
        </p>
      </Fade>
      {/* <br /> */}
      <Fade left>
        <p>
          but as a <span className={styles.span2}>human being</span>
        </p>
      </Fade>
      {/* <br /> */}
      <Fade right>
        <p>who did a crime.&rdquo;</p>
      </Fade>

      <Fade bottom>
        <p className={styles.quote}>— Jan Strømnes, Breaking the Cycle</p>
      </Fade>
    </div>
  )
}

export default QuoteAnimation
