import React, { Component } from "react"

import styles from "./styles.module.less"

import logo from "./img/KmnLogo.png"
import tagline from "./img/Tagline.png"

const NameBlock = () => {
  return (
    <div className={styles.NameBlock}>
      <div className={styles.body}>
        <div className={styles.title}>A name that does justice.</div>
        <div className={styles.description}>
          To us, the above quote was everything. The name needed to focus on the
          here and now. Give people a chance at a fresh start. Demand that
          society takes them as they are today.
        </div>
      </div>
      <div className={styles.logo}>
        <div className={styles.logoHeadline}>Ignore my past.</div>
        <img src={logo} />
      </div>
      <img src={tagline} className={styles.tagline} />
    </div>
  )
}

export default NameBlock
