import React, { Component } from "react"
import styles from "./styles.module.less"
import symbol from "./img/symbol-background.png"
const SymbolBlock = () => {
  return (
    <div className={styles.SymbolBlock}>
      <img src={symbol} className={styles.symbol} />
      <div className={styles.body}>
        <div className={styles.title}> A symbol of reunification.</div>
        <div className={styles.detailsMobile}>
          The name is three words. <br /> The tagline is three phrases.
          <br /> The logo is three shapes— <br />
          each with a more-than-meets-the-eye tale to tell.
        </div>
        <div className={styles.details}>
          The name is three words. The tagline is three phrases. The logo is
          three shapes—each with a more-than-meets-the-eye tale to tell.
        </div>
      </div>
    </div>
  )
}

export default SymbolBlock
