import React, { Component } from "react"
import styles from "./styles.module.less"

import background from "./img/background.png"
import tagline from "./img/Tagline-SVG.svg"

const TaglineBlock = () => {
  return (
    <div className={styles.TaglineBlock}>
      <img src={background} className={styles.background} />
      <div className={styles.body}>
        <div className={styles.title}>A tagline, a journey.</div>
        <div className={styles.detailsMobile}>
          Know Me Now represents a person’s right to change, evolve, outgrow
          their reputation. Though the name heroes the present, we wanted a
          tagline that contextualized the never-finished process: how far
          they’ve come … and how far they’ve yet to go.
        </div>
        <div className={styles.details}>
          Know Me Now represents a person’s right to change, evolve, outgrow
          their reputation. Though the name heroes the present, we wanted a
          tagline that contextualized the never-finished process: how far
          they’ve come … <br /> and how far they’ve yet to go.
        </div>
      </div>
      <img src={tagline} className={styles.tagline} />
    </div>
  )
}

export default TaglineBlock
