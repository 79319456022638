import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

//components
import Name from "./components/Name"
import Tagline from "./components/Tagline"
import Symbol from "./components/Symbol"
import Circles from "./components/Circles"
import Triangle from "./components/Triangle"
import QuoteAnimation from "./components/QuoteAnimation"

import ClosingBlock from "./components/ClosingBlock"
//styles
import styles from "./styles.module.less"

//img
import logo from "./img/KMNLogo.png"
import asiwas from "./img/asiwasblock.png"

export default class Creative extends Component {
  render() {
    return (
      <div className={styles.creative}>
        {/* <ResponsiveMarquee alt="father and daughter"></ResponsiveMarquee> */}
        <div className={styles.marquee}>
          <QuoteAnimation />
        </div>
        <div className={styles.title}>
          <div className={styles.title1}>
            Best-laid plans thrive with best-best
          </div>
          <div className={styles.title2}>CREATIVE</div>
          <div className={styles.title3}>
            This wasn’t a brand pushing a product; this was a movement pressing
            for social change. Every decision needed to reflect immense urgency.
            <br />
            And enormous heart.
          </div>
        </div>
        <div className={styles.logoflex}>
          <div className={styles.imgcontainer}>
            <img className={styles.logo} src={logo} alt="Know Me Now Logo" />
            <img
              className={styles.text}
              src={asiwas}
              alt="As I was. As I am. As I can be."
            />
          </div>
          {/* <div>
          </div> */}
        </div>
        <Reveal>
          <Name />
        </Reveal>
        <Reveal>
          <Tagline />
        </Reveal>
        <Reveal>
          <Symbol />
        </Reveal>
        <Reveal>
          <Circles />
        </Reveal>
        <Reveal>
          <Triangle />
        </Reveal>
        <ClosingBlock />
      </div>
    )
  }
}
