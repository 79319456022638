import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"
import Infographic from "./components/infographic"

import heroImage from "./img/ProblemStatement-HeroImg.jpg"
import contingentHero from "./img/ContingentHero.png"

const ProblemStatement = () => {
  return (
    <div className={styles.ProblemStatement}>
      <Reveal>
        <div className={styles.headlineSection}>
          <p className={styles.headline}>
            Putting a name, face, and action to a social movement.{" "}
          </p>
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.heroSection}>
          <p className={styles.heroTitle}>
            When moms and dads are incarcerated, children pay the price.
          </p>
          <img src={heroImage} className={styles.heroImage} />
        </div>
        <div className={styles.heroSubheader}>
          <p className={styles.shIntro}>Children of incarcerated parents are</p>

          <div className={styles.shLargeText}>
            <div className={styles.dataValue}>6</div>
            <div className={styles.dataValueMultiplier}>x</div>

            <div className={styles.dataDesc}>
              <div>more</div>
              <div>likely</div>
            </div>
          </div>
          <p className={styles.shOutro}>to be incarcerated later in life.</p>
        </div>
      </Reveal>

      <Reveal>
        <div className={styles.breakCopy}>
          The cycle doesn’t break itself— <br />
          in fact, it wants to repeat.
        </div>
      </Reveal>

      <Reveal>
        <div className={styles.percentageBlock}>
          <Infographic className={styles.infographic} />
          <div className={styles.percentageBlockCopy}>
            <div className={styles.percentageHeadline}>
              <div className={styles.percentageValue}>56</div>
              <div className={styles.percentage}>%</div>
            </div>
            <p className={styles.firstSubtext}>
              of formerly incarcerated persons in Oregon
            </p>
            <div className={styles.percentageDetails}>
              are arrested within 3 years
            </div>
            <p className={styles.lastSubtext}>
              of release from prison or felony jail sentence.
            </p>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.contingent}>
          <img src={contingentHero} className={styles.contingentHero} />
          <div className={styles.contingentHeadline}>None of this is okay.</div>
          <div className={styles.contingentDesc}>
            So,
            <a
              href="https://thecontingent.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
              <u>The Contingent</u>
            </a>
            —the same leadership group we collaborated with to bring you{" "}
            <a
              href="https://everychildoregon.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;
              <u>Every Child</u>
            </a>
            —asked us to help them create a critical new program that aims to
            reduce recidivism, thus serving the children, the parents, and the
            communities that incarceration tears apart so well.
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.block}>
          <div className={styles.blockHeadline}>HOW TO GET AFTER IT:</div>
          <div className={styles.blockTitle}>
            Start a program that starts new starts.
          </div>
          <div className={styles.blockCopy}>
            First, give this platform an identity. Create a name, tagline, logo,
            visual language, messaging pillars, and website.
            <br /> <br />
            Second, make a case to government stakeholders to secure funding and
            support. If successful, this pilot program could scale—helping not
            just families in Oregon, but families across America.
            <br />
            <br />
            Third, feel no pressure?
          </div>
        </div>
      </Reveal>
    </div>
  )
}

export default ProblemStatement
