import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"
import { render } from "less"

export default class Infographic extends Component {
  constructor() {
    super()
    this.state = {
      row1: "",
      row2: "",
      row3: "",
      row4: "",
      row5: "",
      row6: "",
      row7: "",
      row8: "",
      row9: "",
    }
  }
  scrollHandler = () => {
    const scroll = window.scrollY
    if (scroll > 1200) {
      this.setState({ row1: `${styles.row1}` })
    }
    if (scroll > 1250) {
      this.setState({ row2: `${styles.row2}` })
    }
    if (scroll > 1300) {
      this.setState({ row3: `${styles.row3}` })
    }
    if (scroll > 1350) {
      this.setState({ row4: `${styles.row4}` })
    }
    if (scroll > 1400) {
      this.setState({ row5: `${styles.row5}` })
    }
    if (scroll > 1450) {
      this.setState({ row6: `${styles.row6}` })
    }
    if (scroll > 1500) {
      this.setState({ row7: `${styles.row7}` })
    }
    if (scroll > 1550) {
      this.setState({ row8: `${styles.row8}` })
    }
    if (scroll > 1600) {
      this.setState({ row9: `${styles.row9}` })
    } else {
      return null
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandler)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler)
  }
  render() {
    return (
      <div className={styles.infographic}>
        <Reveal>
          <div className={styles.svg}>
            <svg
              viewBox="0 -15 800 620"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <circle
                r="16"
                cx="50"
                cy="15"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="85"
                cy="15"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="120"
                cy="15"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="155"
                cy="15"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle r="16" cx="575" cy="15" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="15" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="15" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="15" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="15" fill="#e6e6e7" />
              <circle
                r="16"
                cx="50"
                cy="50"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="85"
                cy="50"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="120"
                cy="50"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="155"
                cy="50"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle
                r="16"
                cx="470"
                cy="50"
                fill="#e6e6e7"
                className={this.state.row1}
              />
              <circle r="16" cx="505" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="50" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="50" fill="#e6e6e7" />
              <circle
                r="16"
                cx="50"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="85"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="120"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="155"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="295"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="330"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="365"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="400"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="435"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="470"
                cy="85"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle r="16" cx="505" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="85" fill="#e6e6e7" />
              <circle r="16" cx="785" cy="85" fill="#e6e6e7" />
              <circle
                r="16"
                cx="85"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="120"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="155"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="190"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="225"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="260"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="295"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="330"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="365"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="400"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="435"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle
                r="16"
                cx="470"
                cy="120"
                fill="#e6e6e7"
                className={this.state.row2}
              />
              <circle r="16" cx="505" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="120" fill="#e6e6e7" />
              <circle r="16" cx="785" cy="120" fill="#e6e6e7" />
              <circle
                r="16"
                cx="85"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="120"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="155"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="190"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="225"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="260"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="295"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="330"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="365"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="400"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="435"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="470"
                cy="155"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle r="16" cx="505" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="155" fill="#e6e6e7" />
              <circle r="16" cx="785" cy="155" fill="#e6e6e7" />
              <circle
                r="16"
                cx="85"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="120"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="155"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="190"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="225"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="260"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="295"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="330"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="365"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="400"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle
                r="16"
                cx="435"
                cy="190"
                fill="#e6e6e7"
                className={this.state.row3}
              />
              <circle r="16" cx="470" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="190" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="190" fill="#e6e6e7" />
              <circle
                r="16"
                cx="85"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="120"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="155"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="190"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="225"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="260"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="295"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="330"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="365"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="400"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="435"
                cy="225"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle r="16" cx="470" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="225" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="225" fill="#e6e6e7" />
              <circle
                r="16"
                cx="85"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="120"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="155"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="190"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="225"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="260"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="295"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="330"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="365"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="400"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle
                r="16"
                cx="435"
                cy="260"
                fill="#e6e6e7"
                className={this.state.row4}
              />
              <circle r="16" cx="470" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="260" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="260" fill="#e6e6e7" />
              <circle
                r="16"
                cx="50"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="85"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="120"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="155"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="190"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="225"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="260"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="295"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="330"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="365"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="400"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="435"
                cy="295"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle r="16" cx="470" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="295" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="295" fill="#e6e6e7" />
              <circle
                r="16"
                cx="50"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="85"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="120"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="155"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="190"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="225"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="260"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="295"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="330"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="365"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="400"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle
                r="16"
                cx="435"
                cy="330"
                fill="#e6e6e7"
                className={this.state.row5}
              />
              <circle r="16" cx="470" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="330" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="330" fill="#e6e6e7" />
              <circle
                r="16"
                cx="15"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="50"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="85"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="120"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="155"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="190"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="225"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="260"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="295"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="330"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="365"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="400"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="435"
                cy="365"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle r="16" cx="470" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="365" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="365" fill="#e6e6e7" />
              <circle
                r="16"
                cx="15"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="50"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="85"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="120"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="155"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="190"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="225"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="260"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="295"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="330"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="365"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="400"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle
                r="16"
                cx="435"
                cy="400"
                fill="#e6e6e7"
                className={this.state.row6}
              />
              <circle r="16" cx="470" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="400" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="400" fill="#e6e6e7" />
              <circle
                r="16"
                cx="15"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="50"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="85"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="120"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="155"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="190"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="225"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="260"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="295"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="330"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="365"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="400"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="435"
                cy="435"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle r="16" cx="470" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="435" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="435" fill="#e6e6e7" />
              <circle
                r="16"
                cx="15"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="50"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="85"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="120"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="155"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="190"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="225"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="260"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="295"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="330"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="365"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="400"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle
                r="16"
                cx="435"
                cy="470"
                fill="#e6e6e7"
                className={this.state.row7}
              />
              <circle r="16" cx="470" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="470" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="470" fill="#e6e6e7" />
              <circle
                r="16"
                cx="15"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="50"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="85"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="120"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="155"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="190"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="225"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="260"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="295"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="330"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="365"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="400"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="435"
                cy="505"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle r="16" cx="470" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="505" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="505" fill="#e6e6e7" />
              <circle
                r="16"
                cx="50"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="85"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="120"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="155"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="190"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="225"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="260"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="295"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="330"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="365"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="400"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle
                r="16"
                cx="435"
                cy="540"
                fill="#e6e6e7"
                className={this.state.row8}
              />
              <circle r="16" cx="470" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="540" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="540" fill="#e6e6e7" />
              <circle
                r="16"
                cx="50"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="85"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="120"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="155"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="190"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="225"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="260"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="295"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="330"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="365"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="400"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle
                r="16"
                cx="435"
                cy="575"
                fill="#e6e6e7"
                className={this.state.row9}
              />
              <circle r="16" cx="470" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="505" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="540" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="575" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="610" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="645" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="680" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="715" cy="575" fill="#e6e6e7" />
              <circle r="16" cx="750" cy="575" fill="#e6e6e7" />
            </svg>
          </div>
        </Reveal>
      </div>
    )
  }
}
