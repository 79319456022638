import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"
import BlockData from "./components/BlockData"

const items = [
  {
    number: "30",
    activity: "Connect Kits",
  },
  {
    number: "90",
    activity: "New Beginning bags",
  },
  {
    number: "320",
    activity: "letters written",
  },
  {
    number: "395",
    activity: "Instagram followers",
  },
  {
    number: "1,105",
    activity: "Facebook likes",
  },
  {
    number: "26,556",
    activity: "site clicks",
  },
  {
    number: "281,280",
    activity: "people reached",
  },
  {
    number: "1.5M+",
    activity: "ad impressions",
  },
]

const ClosingBlock = () => {
  return (
    <div>
      <Reveal>
        <div className={styles.block}>
          <div className={styles.blockTitle}>HOW IT WORKED OUT:</div>
          <div className={styles.blockCopy}>
            Desperate times called for decisive measures. Thesis delivered an
            all-up brand identity and website that put the developing initiative
            in a strong position to fill a critical social need.
            <br /> <br />
            Know Me Now debuted in Oregon in January 2020 in front of government
            stakeholders, journalists, and advocates. As of July 1, 2020, the
            pilot program has generated:
          </div>
          <BlockData items={items} />
          <div className={styles.closing}>
            But the program is still young. <br />
            And the work is far from over.
          </div>
        </div>
      </Reveal>
    </div>
  )
}

export default ClosingBlock
