import React from "react"
import { Link } from "gatsby"
import styles from "./styles.module.less"

const CaseStudyNav = () => {
  return (
    <section data-component="CaseStudyNav" className={styles.CaseStudyNav}>
      <div className={styles.bg}>
        <div className={styles.cta}>
          <Link to="/case-studies/adobe-seo">
            <span className={styles.left}>Previous</span>
          </Link>
          <Link to="/case-studies/tacobell">
            <span className={styles.right}>Read next</span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyNav
