import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

import CaseStudyNav from "./components/CaseStudyNav"

const Credits = () => {
  return (
    <div className={styles.Credits}>
      <div className={styles.body}>
        <div className={styles.title}>Credits</div>
        <div className={styles.headline}>
          Thanks to the Know Me Now team. AKA the real people who made the real
          work. And a special shout-out to the SWAT team of Project Managers who
          came together to set this expedited project up for success.
        </div>

        <div className={styles.team}>
          <div className={styles.teamTitle}>Account Manager:</div>
          <div className={styles.names}>Shy Allott</div>
        </div>

        <div className={styles.team}>
          <div className={styles.teamTitle}>Project Managers:</div>
          <div className={styles.names}>
            Chelsi Deymonaz, Regina Atoigue, Megan Trevarthen,&nbsp;
          </div>
          <div className={styles.names}>Rachael Cervenka, Patrick Wells</div>
        </div>

        <div className={styles.team}>
          <div className={styles.teamTitle}>Strategy:</div>
          <div className={styles.names}>Scott Lansing, McKenna Albion</div>
        </div>

        <div className={styles.team}>
          <div className={styles.teamTitle}>Design: </div>
          <div className={styles.names}>
            Denny Robles, Angie Maurer, Nandya Thio
          </div>
        </div>

        <div className={styles.team}>
          <div className={styles.teamTitle}>Development: </div>
          <div className={styles.names}>
            Michael Barrett, Madelaine Trujillo
          </div>
        </div>

        <div className={styles.team}>
          <div className={styles.teamTitle}>Writing: </div>
          <div className={styles.names}>
            Tyler Stenson, Jenn Pidgeon, Joe Streckert
          </div>
        </div>
      </div>
      <div className={styles.nav}>
        <CaseStudyNav />
      </div>
    </div>
  )
}

export default Credits
