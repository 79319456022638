import React, { Component } from "react"
import styles from "./styles.module.less"

import circle from "./img/circle-bg.png"

const CircleBlock = () => {
  return (
    <div className={styles.CircleBlock}>
      <div className={styles.body}>
        <div className={styles.title}> The circles.</div>
        <div className={styles.details}>
          Notice they’re imperfect. One big, one small, illustrative of an adult
          holding a child. A reunited family or the madonna? Either way.
        </div>
      </div>
      <img src={circle} className={styles.circle} />
    </div>
  )
}

export default CircleBlock
