import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"
import StrategySteps from "./components/StrategySteps"

import stepsBanner from "./img/stepsBanner.png"
import childParent from "./img/ChildParent.png"
import yellowConvoBubble from "./img/yellowConvoBubble.png"
import orangeConvoBubble from "./img/orangeConvoBubble.png"

const items = [
  {
    stepNumber: "Step 1:",
    title: "Belief",
    subTitle: "that builds.",
    body:
      "Belief quiets doubt and inspires action. It sustains transition by building momentum and confidence through continuous connections with family and community.",
  },
  {
    stepNumber: "Step 2:",
    title: "Growth",
    subTitle: "that stabilizes.",
    body:
      "Growth is an incremental process. Determination and stamina are required to break the cycle of incarceration and achieve stability with staying power.",
  },
  {
    stepNumber: "Step 3:",
    title: "Legacies",
    subTitle: "that last.",
    body:
      "Legacies define families and communities. Taking ownership of your actions ensures your life will be remembered for your achievements, not your incarceration.",
  },
]

const StrategyBeliefs = () => {
  return (
    <div className={styles.StrategyBeliefs}>
      <Reveal>
        <div className={styles.steps}>
          <img src={childParent} className={styles.childParent} />
          <StrategySteps items={items} />
          <img src={stepsBanner} className={styles.banner} />
        </div>
      </Reveal>
      <Reveal>
        <div className={styles.conversation}>
          <div className={styles.leftConvo}>
            <img src={yellowConvoBubble} className={styles.yellowConvoBubble} />
            <div className={styles.leftConvoCopy}>
              Now that’s a real program with real staying power.
            </div>
          </div>

          <div className={styles.rightConvo}>
            <img src={orangeConvoBubble} className={styles.orangeConvoBubble} />
            <div className={styles.rightConvoCopy}>
              Sure is. But what are we calling it?
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  )
}

export default StrategyBeliefs
