import React, { Component } from "react"

import styles from "./styles.module.less"

const Item = ({ item }) => (
  <div className={styles.BlockData}>
    <div className={styles.number}>{item.number}</div>
    <div className={styles.activity}>{item.activity}</div>
  </div>
)

const BlockData = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <Item key={`Item_${index}`} item={item} />
      ))}
    </div>
  )
}

export default BlockData
