import React from "react"

import KnowMeNow from "components/CaseStudies/KnowMeNow"
import Layout from "components/Layout"
import SEO from "components/seo"

const KnowMeNowCaseStudy = () => (
  <Layout backgroundColor={"white"} bannerBgColor="" isArchive>
    <SEO
      title="Thesis | Case study: Know Me Now brand identity"
      description="We developed a digital brand identity for Know Me Now, a critical social initiative that aims to reduce prison-return rates in Oregon by building family-centric support systems. Read the case study."
    />
    <KnowMeNow />
  </Layout>
)

export default KnowMeNowCaseStudy
