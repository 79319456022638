import React, { Component } from "react"

//Styles
import styles from "./styles.module.less"
//Images
import dot from "./img/theDot.png"
import banner from "./img/banner.png"
import heromobile from "./img/heromobile.jpg"
import herotablet from "./img/herotablet.jpg"
import herodesktop from "./img/herodesktop.jpg"

export default class Marquee extends Component {
  render() {
    return (
      <div className={styles.CodeHero}>
        <div className={styles.hero}>
          <img
            className={styles.marquee}
            src={heromobile}
            srcSet={`${heromobile} 1200w, 
            ${herotablet} 1960w, 
            ${herodesktop} 3000w`}
            alt="hero image"
          />
          <img className={styles.banner} src={banner} alt="green banner" />
          <img className={styles.dot} src={dot} alt="yellow dots" />
          <div className={styles.headline}>
            <div className={styles.textsm}>CASE STUDY: KNOW ME NOW</div>
            <div className={styles.textlg}>
              Introducing an effort
              <br />
              to reintroduce families.
            </div>
          </div>
        </div>
      </div>
    )
  }
}
