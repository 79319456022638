import React, { Component } from "react"
import styles from "./styles.module.less"

import triangle from "./img/triangle-bg.png"

const TriangleBlock = () => {
  return (
    <div className={styles.TriangleBlock}>
      <div className={styles.headline}>
        <div className={styles.title}> The triangle.</div>
        <div className={styles.details}>
          A roof over the family’s head? A mountain—a beacon of the northwest
          and the hill ahead to climb? Clever, but not quite.
        </div>
      </div>
      <div className={styles.answer}>
        Answer: It’s a kite on its side. Because a “kite” is what adults in
        custody call the letters they write home—a symbol of their familial
        connection, at the mercy of the wind.
      </div>
      <img src={triangle} className={styles.triangle} />
    </div>
  )
}

export default TriangleBlock
