import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

//components
import ClosingBlock from "./components/ClosingBlock"

//images
/* start marquee images */
import marqueeMobile from "./img/Tech-mobile.png"
import marqueeTablet from "./img/Tech-tablet.png"
import marqueeDesktop from "./img/Tech-desktop.png"
/* end marquee images */

import laptopImg from "./img/laptop.png"

/* start letsGetStarted images */
import lgsMobile from "./img/LGS-mobile.png"
import lgsTablet from "./img/LGS-tablet.png"
import lgsDesktop from "./img/LGS-desktop.png"
/* end letsGetStarted images */

import emailImageOne from "./img/knowOurTeam.png"
import emailImageTwo from "./img/servingEveryone.png"

/* start knowOurNeeds images */
import konMobile from "./img/KON-mobile.png"
import konDesktop from "./img/KON-desktop.png"
/* end knowOurNeeds images */

const TechBlock = () => {
  return (
    <div className={styles.TechBlock}>
      <Reveal>
        <img src={marqueeMobile} className={styles.marqueeMobile} />
        <img src={marqueeTablet} className={styles.marqueeTablet} />
        <img src={marqueeDesktop} className={styles.marqueeDesktop} />
      </Reveal>

      <Reveal>
        <div className={styles.header}>
          <div className={styles.headerCopy}>
            <div className={styles.headline}>
              Actualizing ideas takes real-world
            </div>
            <div className={styles.headerTitle}>TECH</div>
            <div className={styles.headerDetails}>
              As a nonprofit, Know Me Now needed a lean website solution to get
              them up and running <i>now</i> while giving them the autonomy to
              make their own updates down the road.
            </div>
          </div>
        </div>
      </Reveal>

      <Reveal>
        <div className={styles.laptop}>
          <img src={laptopImg} className={styles.laptopImg} />
          <div className={styles.laptopCopy}>
            <div className={styles.copyBlockTitle}>That meant</div>
            <div className={styles.copyBlockDetails}>
              Building a custom WordPress theme with custom editing tools to
              create new site content on the fly, in-house, without breaking the
              fluid, <br />
              dynamic design.
            </div>
          </div>
        </div>
      </Reveal>

      <Reveal>
        <div className={styles.letsGetStarted}>
          <img src={lgsMobile} className={styles.lgsMobile} />
          <img src={lgsTablet} className={styles.lgsTablet} />
          <img src={lgsDesktop} className={styles.lgsDesktop} />

          <div className={styles.letsGetStartedCopy}>
            <div className={styles.copyBlockTitle}>That meant</div>
            <div className={styles.copyBlockDetails}>
              Embedding email sign-up forms that directly integrate with their
              CRM tooling.
            </div>
          </div>
        </div>
      </Reveal>

      <Reveal>
        <div className={styles.rockSolid}>
          <div className={styles.images}>
            <img src={emailImageOne} className={styles.emailImageOne} />
            <img src={emailImageTwo} className={styles.emailImageTwo} />
          </div>
          <div className={styles.rockSolidCopy}>
            <div className={styles.copyBlockTitle}>That meant</div>
            <div className={styles.copyBlockDetails}>
              Providing rock-solid WordPress hosting with automatic security
              updates and developer support, including demo and QA environments.
            </div>
          </div>
        </div>
      </Reveal>

      <Reveal>
        <div className={styles.knowOurNeeds}>
          <img src={konMobile} className={styles.konMobile} />
          <img src={konDesktop} className={styles.konDesktop} />
        </div>
      </Reveal>

      <Reveal>
        <ClosingBlock />
      </Reveal>
    </div>
  )
}

export default TechBlock
