import React from "react"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

const Item = ({ item }) => (
  <div className={styles.StrategySteps}>
    <div className={styles.stepNumber}>{item.stepNumber}</div>
    <div className={styles.title}>{item.title}</div>
    <div className={styles.subTitle}>{item.subTitle}</div>
    <div className={styles.body}>{item.body}</div>
  </div>
)

const StrategySteps = ({ items }) => {
  return (
    <div className={styles.list}>
      <Reveal>
        {items.map((item, index) => (
          <Item key={`Item_${index}`} item={item} />
        ))}
      </Reveal>
    </div>
  )
}

export default StrategySteps
