import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

//styles
import styles from "./styles.module.less"

//img
import hero from "./img/hero.jpg"
import heromob from "./img/heromobile.jpg"
import herotab from "./img/herotablet.jpg"
import hands from "./img/hands.jpg"
import people from "./img/womanandchild.jpg"
import peoplemob from "./img/womanandchildmobile.jpg"
import peopletab from "./img/womanandchildtablet.jpg"

const ResponsiveMarquee = () => (
  <img src={hero} srcSet={`${heromob} 750w, ${herotab} 1536w, ${hero} 2680w`} />
)
const ResponsivePeople = ({ large, medium, small }) => (
  <img
    src={peoplemob}
    srcSet={`${small} 750w, ${medium} 1536w, ${large} 2680w`}
  />
)

export default class Strategy extends Component {
  render() {
    return (
      <div className={styles.strategy}>
        <Reveal>
          <ResponsiveMarquee alt="father and daughter" />
        </Reveal>

        <Reveal>
          <div className={styles.title}>
            <div className={styles.title1}>
              Strong results start with a strong
            </div>
            <div className={styles.title2}>STRATEGY</div>
            <div className={styles.title3}>
              This was an urgent need, and we responded with urgency in kind.
            </div>
          </div>
        </Reveal>

        <Reveal>
          <div className={styles.flexcolumn}>
            <div className={styles.pod1copy}>
              <p className={styles.headline}>Our mission.</p>
              <p>
                To reduce prison-return rates by building family-centric support
                systems—in homes and neighborhoods—that empower an incarcerated
                parent’s right to change, grow, and enhance their legacy.
              </p>
              <p>
                Desk research wasn’t gonna be enough. We needed to have
                meaningful conversations (’cause that’s how meaningful work gets
                done).
              </p>
            </div>
            <Reveal>
              <div className={styles.reverse}>
                <img src={hands} alt="hands holding" />
              </div>
            </Reveal>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.pod2copy}>
            <div className={styles.paracontainer}>
              <p>
                <strong>We walked in their shoes.</strong>
                <br /> Visited prisons, listened to incarcerated people, and
                took their lived experiences seriously.
              </p>
              <p>
                <strong>We reached across the aisle.</strong>
                <br /> We partnered with the Department of Corrections, engaging
                in constant, open communication to ensure a viable program.
              </p>
              <div className={styles.hiddentext}>
                <p>
                  Dropping folks off at the corner wasn’t gonna cut it. We
                  needed a strategic roadmap that ushered incarcerated parents
                  from jailhouse to their house, demonstrating a commitment to
                  their much-needed (but not-easy) personal journey.
                </p>
              </div>
            </div>
            <Reveal>
              <div className={styles.people}>
                <img src={people} alt="woman and child" />
              </div>
            </Reveal>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.pod3container}>
            <ResponsivePeople
              alt="woman and child"
              small={peoplemob}
              medium={peopletab}
              large={people}
            />
            <p className={styles.pod3copy}>
              Dropping folks off at the corner wasn’t gonna cut it. We needed a
              strategic roadmap that ushered incarcerated parents from jailhouse
              to their house, demonstrating a commitment to their much-needed
              (but the not-easy) personal journey.
            </p>
          </div>
        </Reveal>
      </div>
    )
  }
}
