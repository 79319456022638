import React from "react"

import Marquee from "./components/Marquee"
import StrategyBeliefs from "./components/StrategyBeliefs"
import ProblemStatement from "./components/ProblemStatement"
import Strategy from "./components/Strategy"
import Creative from "./components/Creative"

import Credits from "./components/Credits"
import Tech from "./components/Tech"

const KnowMeNow = () => (
  <div>
    <Marquee />
    <ProblemStatement />
    <Strategy />
    <StrategyBeliefs />
    <Creative />
    <Tech />
    <Credits />
  </div>
)

export default KnowMeNow
